/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Compact Fluorescent` - Compact Fluorescent
 * * `Incandescent` - Incandescent
 * * `LED` - Led
 * * `T12` - T12
 * * `T8` - T8
 * * `T5` - T5
 * * `Other` - Other
 */
export enum LampCategoryEnum {
    COMPACT_FLUORESCENT = 'Compact Fluorescent',
    INCANDESCENT = 'Incandescent',
    LED = 'LED',
    T12 = 'T12',
    T8 = 'T8',
    T5 = 'T5',
    OTHER = 'Other',
}
