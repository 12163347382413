/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Tank` - Tank
 * * `Instantaneous` - Instantaneous
 */
export enum HeaterTypeEnum {
    TANK = 'Tank',
    INSTANTANEOUS = 'Instantaneous',
}
