import { Collection } from "resourcerer";

import { UtilityProvider } from "@/Api/generated";

export default class UtilityProvidersCollection extends Collection<UtilityProvider> {
  static comparator = "name";

  parse(response: UtilityProvider[]) {
    return response.filter(({ name }) => !!name?.trim());
  }

  url() {
    return "/api/utility_providers";
  }

  static idAttribute = "utility_provider_id";

  static dependencies = ["type", "property_token"];
}
