/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `GRID` - Grid
 * * `SOLAR` - Solar
 * * `WIND` - Wind
 * * `NATURAL_GAS` - Natural Gas
 * * `PROPANE` - Propane
 * * `FUEL_OIL` - Fuel Oil
 * * `DIESEL` - Diesel
 * * `COAL` - Coal
 * * `COKE` - Coke
 * * `WOOD` - Wood
 * * `KEROSENE` - Kerosene
 * * `STEAM` - Steam
 * * `CHILLED_WATER` - Chilled Water
 * * `HOT_WATER` - Hot Water
 */
export enum EnergySubtypeEnum {
    GRID = 'GRID',
    SOLAR = 'SOLAR',
    WIND = 'WIND',
    NATURAL_GAS = 'NATURAL_GAS',
    PROPANE = 'PROPANE',
    FUEL_OIL = 'FUEL_OIL',
    DIESEL = 'DIESEL',
    COAL = 'COAL',
    COKE = 'COKE',
    WOOD = 'WOOD',
    KEROSENE = 'KEROSENE',
    STEAM = 'STEAM',
    CHILLED_WATER = 'CHILLED_WATER',
    HOT_WATER = 'HOT_WATER',
}
