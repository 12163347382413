/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `ELECTRIC` - Electric
 * * `FUELS` - Fuels
 * * `DISTRICT_ENERGY` - District Energy
 * * `ELECTRICITY` - Electricity
 * * `NATURAL_GAS` - Natural Gas
 */
export enum RetrofitEnergyImpactAnalysisEnergyTypeEnum {
    ELECTRIC = 'ELECTRIC',
    FUELS = 'FUELS',
    DISTRICT_ENERGY = 'DISTRICT_ENERGY',
    ELECTRICITY = 'ELECTRICITY',
    NATURAL_GAS = 'NATURAL_GAS',
}
