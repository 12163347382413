/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Manual` - Manual
 * * `Occupancy Sensor` - Occupancy Sensor
 * * `Daylight Sensor` - Daylight Sensor
 * * `Time Clock` - Time Clock
 * * `BMS Integration` - Bms Integration
 * * `Other` - Other
 */
export enum LightingDetailControlTypeEnum {
    MANUAL = 'Manual',
    OCCUPANCY_SENSOR = 'Occupancy Sensor',
    DAYLIGHT_SENSOR = 'Daylight Sensor',
    TIME_CLOCK = 'Time Clock',
    BMS_INTEGRATION = 'BMS Integration',
    OTHER = 'Other',
}
