import Button from "@/components/Button";
import IconButton from "@/components/Button/IconButton";
import HovercardToggle from "@/components/HovercardToggle";
import SelectableList from "@/components/SelectableList";
import Spinner from "@/components/Spinner";

import { getPortfolioOrg } from "@/js/core/coreResources";
import useLocalStorage from "@/js/hooks/useLocalStorage";
import useNotificationContext from "@/js/hooks/useNotificationContext";
import useSaveReducer from "@/js/hooks/useSaveReducer";
import { UnspoofModel } from "@/js/models/SpoofModel";
import { isSpoofing } from "@/js/utils/authentication";
import { classnames } from "@/js/utils/cambio";

/**
 * Banner for the top of the app while in spoofing mode to make clear that we're in spoof mode.
 * Also has a button to unspoof and move in and out of boss mode (which is simple localStorage
 * flag).
 */
export default function SpoofBanner() {
  const [{ isSaving }, saveDispatch] = useSaveReducer();
  const { notify } = useNotificationContext();
  const [isInBossMode, setBossMode, removeBossMode] = useLocalStorage("bossMode", null, {
    global: true,
  });
  const [isInPresentationMode, setPresentationMode, removePresentationMode] = useLocalStorage(
    "presentationMode",
    null,
    { global: true },
  );

  const onUnspoof = () => {
    saveDispatch({ type: "saving" });

    new UnspoofModel()
      .save()
      .then(() => {
        removeBossMode();
        window.location.assign("/");
      })
      .catch(() => {
        saveDispatch({ type: "reset" });
        notify("Could not end spoof session");
      });
  };

  return isSpoofing() ?
      <div
        className={classnames("SpoofBanner", {
          presentation: isInPresentationMode,
          "boss-mode": isInBossMode,
        })}
      >
        {!isInPresentationMode ?
          <>
            <div>
              <h2>CURRENTLY SPOOFING: {getPortfolioOrg().organization__name}</h2>
              <span>
                <small>ID: {getPortfolioOrg().organization__token}</small>
              </span>
            </div>
            <div>
              <Button
                flavor="primary"
                onClick={() => (!isInBossMode ? setBossMode(true) : removeBossMode())}
              >
                {isInBossMode ? "Exit" : "Enter"} Boss Mode
              </Button>
              <Button disabled={isSaving} flavor="primary" onClick={onUnspoof}>
                {isSaving ?
                  <Spinner flavor="inline" />
                : null}
                Unspoof
              </Button>
              <HovercardToggle
                alignment="right"
                contents={() => (
                  <SelectableList
                    items={[
                      {
                        key: "copy-link",
                        display: "Copy spoofing link",
                        onClick: async () => {
                          const search = new URLSearchParams(window.location.search);

                          search.set("o", getPortfolioOrg().organization__token);

                          await navigator.clipboard.writeText(
                            `${window.location.href.split("?")[0]}?${search.toString()}`,
                          );
                        },
                      },
                      {
                        key: "presentation-mode",
                        display: "Enter Presentation Mode",
                        onClick: () => setPresentationMode(true),
                      },
                    ]}
                  />
                )}
              >
                <Button label="Overflow" icon="kebab" />
              </HovercardToggle>
            </div>
          </>
        : <IconButton icon="arrow-back" onClick={() => removePresentationMode()} />}
      </div>
    : null;
}
