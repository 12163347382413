import { useRouter } from "next/router";
import { ExecutorFunction, useResources } from "resourcerer";

import PropertyDisplay from "@/components/PropertyDisplay";
import SearchSelect from "@/components/Select/SearchSelect";

import { getPortfolioOrg } from "@/js/core/coreResources";
import { ClientEnhancedSpace } from "@/js/models/PropertiesCollection";
import { track } from "@/js/services/mixpanel";

const getResources: ExecutorFunction<"properties"> = (props) => ({
  properties: { params: { organization_token: getPortfolioOrg().organization__token } },
});

/**
 * Does a FE search filter on properties via our SearchSelect component, and then navigates
 * to a selected property.
 */
export default function PropertySearch() {
  const router = useRouter();
  const { hasLoaded, propertiesCollection } = useResources(getResources, {});

  return (
    <div className="PropertySearch">
      <SearchSelect
        key={router.query.space_token as string}
        hovercardClassName="property-search-hovercard"
        placeholder="Find a property"
        disabled={!hasLoaded}
        onSelect={() => track("Property Search - Result Clicked")}
        options={propertiesCollection.toJSON().map((property) => ({
          href: `/${property.sub_portfolio_token}/properties/${property.token}/overview`,
          display: (
            <PropertyDisplay
              property={{
                ...property,
                address: {
                  state: property.address__state,
                  city: property.address__city,
                  full_country_name: property.address_full_country_name,
                },
              }}
              withImage
            />
          ),
          matchText: getMatchText(property),
          value: property.token,
        }))}
        size="small"
      />
    </div>
  );
}

function getMatchText(property: ClientEnhancedSpace) {
  return [
    property.name,
    property.address__street_line_1,
    property.address__street_line_2,
    property.address__city,
    property.address__state,
    property.address_full_country_name,
  ]
    .join("_")
    .toLowerCase();
}
