// Context incorrect onboarding links were sent out, this is a list of onboarding tokens which should be overridden to be in
export const temporaryOnboardingLinkOverrides = [
  // Pembroke Place
  "ENvSa4QgHCYTPcMvhWVjOz2nSSYhdxdGq4KD_qNgJwmRxeXQIfm7g2Mp3SdYak8Y",
  // Aberdeen Square
  "ggAhklAQ5JGl5AEIK_7x2ZzRJtXftd_VNvWf-MvLm66GbkBb4sPI-CqfDw5Z6zwU",
  // Naranja Lakes
  "rUVE1XJ3qcxbfn5uEjBicjD7kiY4En5-bharn9PR1_E8zWEaFb0tK5FBLbESv5cD",
  // Plaza de Oro
  "_zM2mJ--aYlV-Ih-I1eIKPSWGS2IB21nQ_fYpTIpNZ-6WDu2jYhByZ7ZYpvLm3hz",
  // Chickasaw Trail
  "tAH_DnPFrBycFOGAVUlWOzmOvfsfbknurxnbyU9X0MANnETzAEsswO6DkVGAy6KI",
  // El Paraiso
  "gAeYWhyQ7LhRwSSvlHAUzYJ3OsrjBKrPJFr1J7dL8S0s0YHMfiYjPzrG1LjZnhPO",
  // Crossroads Square
  "5Czg3LoP22MZNAsgzpQziOs6xe4q-NTLW73cMfpF3PRa4QeyoIgzOxRvmSVsRsyO",
];

export enum SystemOverrideTokens {
  DEMO_SPACE_TOKEN_MADISON = "s_3KnBTRhgMnHLZVWuCdSg3f",
  OXFORD_BACKBAY_TOKEN = "s_eMMmQNYUfw98MJK7BWQEtX",
  OXFORD_FRANKLIN_TOKEN = "s_hdZfKA5QEn4wmi2uXSB6ry",
  OXFORD_NYA_TOKEN = "s_32o4V6aiL68ZdHyvHeaqMs",
  OXFORD_900_16th_TOKEN = "s_ygkoFYDQHHqPf8degYNgWw",
  NUVEEN_EAST_SEGO = "s_KCixRxPFKjMF2ksYqHWrih",
  NUVEEN_DEFOOR = "s_evbZce9siz9gJexWX9cpcB",
  NUVEEN_KIRKLAND = "s_36KQC29SZFsKZJv8GtUkgK",
  CROWN_UNIVERSITY_AVENUE = "s_RXAaFq2iaQfi5h8HBoySf6",
  CROWN_SHEPPARD = "s_42Cxz2tNj74Tsbn8YhuUbs",
  CROWN_KING_WEST = "s_3dR3eVNkqrhRDHZ2d26CyF",
  CARDINAL_COPPER_SOCIAL_TOKEN = "s_Y2ZfNSiq6WH4Gk8jYD3bkB",
  CARDINAL_FIFTY_TWENTY_FIVE = "s_6wddSa9FhVRashQSDDi9Y7",
  OXFORD_PASUBIO_CENTRALE = "s_8JeqetB5XvHfyijiqRiW8y",
  OXFORD_ONE_BOSTON_PLACE = "s_hkAzvCG6mCwxbte3YcCPiC",
  OXFORD_KARPERSTRAAT_HQ = "s_eWRg4z4QKtC8C2euyFA7sH",
  OXFORD_SUNNYVALE_APARTMENTS_NORTH = "s_3nDwtP3NQau4Gmb7oXFukv",
  OXFORD_SUNNYVALE_APARTMENTS_SOUTH = "s_WKd7P6dEkZ9F98Uwu7zdoF",
  OXFORD_NEW_YORK_AVE = "s_f6omckvqS3KMbnA549RhK2",
}

// We have turned on binning and ranking feature for these properties, so the
//   hardcoded values are no longer needed for these properties, we have deleted the values,
//   Ideally we would remove all hardcoded SystemOverrideTokens as well,
//   but some of them are still referenced somewhere in the code, so we are keeping them
//   but need return false to avoid using the removed hardcoded values.
export const removedHardcodedSpaces = [
  SystemOverrideTokens.CARDINAL_COPPER_SOCIAL_TOKEN,
  SystemOverrideTokens.CARDINAL_FIFTY_TWENTY_FIVE,
  SystemOverrideTokens.OXFORD_BACKBAY_TOKEN,
  SystemOverrideTokens.OXFORD_FRANKLIN_TOKEN,
  SystemOverrideTokens.OXFORD_NYA_TOKEN,
  SystemOverrideTokens.OXFORD_900_16th_TOKEN,
];

export function isHardcodedSystemSpace(spaceToken: string): boolean {
  const enumValues: string[] = Object.values(SystemOverrideTokens);

  if (removedHardcodedSpaces.includes(spaceToken as SystemOverrideTokens)) {
    return false;
  }

  return enumValues.includes(spaceToken);
}

export function carbonROIRank(system: string, spaceToken: string) {
  if (spaceToken === SystemOverrideTokens.CROWN_UNIVERSITY_AVENUE) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_KING_WEST) {
    return system === "total" ? 3 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_SHEPPARD) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.DEMO_SPACE_TOKEN_MADISON) {
    switch (system) {
      case "lighting":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 3;
      case "envelope":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      default:
        return null;
    }
  }

  // Nuveen

  if (spaceToken === SystemOverrideTokens.NUVEEN_EAST_SEGO) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.NUVEEN_DEFOOR) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 2;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.NUVEEN_KIRKLAND) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 2;
      case "controls":
        return 2;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  }

  if (spaceToken === SystemOverrideTokens.OXFORD_PASUBIO_CENTRALE) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_ONE_BOSTON_PLACE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_KARPERSTRAAT_HQ) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 3;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_NORTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 3;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_SOUTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NEW_YORK_AVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 3;
      default:
        return null;
    }
  } else {
    return null;
  }
}

export function financialROIRank(system: string, spaceToken: string) {
  if (spaceToken === SystemOverrideTokens.CROWN_UNIVERSITY_AVENUE) {
    return system === "total" ? 3 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_KING_WEST) {
    return system === "total" ? 1 : null;
  }

  if (spaceToken === SystemOverrideTokens.CROWN_SHEPPARD) {
    return system === "total" ? 2 : null;
  }

  if (spaceToken === SystemOverrideTokens.DEMO_SPACE_TOKEN_MADISON) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 1;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  }

  // Nuveen
  else if (spaceToken === SystemOverrideTokens.NUVEEN_KIRKLAND) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 3;
      case "controls":
        return 3;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.NUVEEN_DEFOOR) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 4;
      case "lighting":
        return 2;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.NUVEEN_EAST_SEGO) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "lighting":
        return 4;
      case "envelope":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_PASUBIO_CENTRALE) {
    switch (system) {
      case "total":
        return 4;
      case "heating":
        return 4;
      case "cooling":
        return 2;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_ONE_BOSTON_PLACE) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_KARPERSTRAAT_HQ) {
    switch (system) {
      case "total":
        return 2;
      case "heating":
        return 1;
      case "cooling":
        return 1;
      case "hotwater":
        return 3;
      case "controls":
        return 4;
      case "envelope":
        return 3;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_NORTH) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 3;
      case "cooling":
        return 3;
      case "hotwater":
        return 4;
      case "controls":
        return 2;
      case "envelope":
        return 4;
      case "lighting":
        return 4;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_SUNNYVALE_APARTMENTS_SOUTH) {
    switch (system) {
      case "total":
        return 1;
      case "heating":
        return 2;
      case "cooling":
        return 2;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 1;
      default:
        return null;
    }
  } else if (spaceToken === SystemOverrideTokens.OXFORD_NEW_YORK_AVE) {
    switch (system) {
      case "total":
        return 3;
      case "heating":
        return 4;
      case "cooling":
        return 4;
      case "hotwater":
        return 4;
      case "controls":
        return 1;
      case "envelope":
        return 4;
      case "lighting":
        return 2;
      default:
        return null;
    }
  } else {
    return null;
  }
}
