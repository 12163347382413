import type { ReactNode } from "react";

import { Tooltip } from "antd";
import { useRef, useState } from "react";

import { classnames } from "@/js/utils/cambio";

/**
 * A component that shows an ellipsis if the text node overflows its container; this happens in CSS.
 * It also shows a tooltip with the full text when hovered over; this happens in JS in this
 * component.
 */
export default function TextOverflow({
  children,
  lines = 1,
}: {
  children: ReactNode;
  lines?: number;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const overflowRef = useRef<HTMLSpanElement>();
  const isMultiline = lines > 1;

  const onMouseEnter = () => {
    if (
      overflowRef.current.offsetWidth < overflowRef.current.scrollWidth ||
      (isMultiline && overflowRef.current.offsetHeight < overflowRef.current.scrollHeight)
    ) {
      setShowTooltip(true);
    }
  };

  const onMouseLeave = () => {
    if (setShowTooltip) {
      setShowTooltip(false);
    }
  };

  return (
    <span
      ref={overflowRef}
      className={classnames("TextOverflow", { multiline: isMultiline })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...(isMultiline ? { style: { WebkitLineClamp: lines } } : {})}
    >
      {showTooltip ?
        <Tooltip title={children}>{children}</Tooltip>
      : children}
    </span>
  );
}
