import type { IconsType } from "../SvgIcon";

import { MouseEvent, ReactNode, useEffect, useRef } from "react";

import Button, { ButtonProps } from "@/components/Button";

import { classnames, noop } from "@/js/utils/cambio";

import SvgIcon from "../SvgIcon";
import TextOverflow from "../TextOverflow";

export interface SelectableListItem extends ButtonProps {
  key: string | number;
  display: ReactNode;
  // light text underneath main display
  secondaryDisplay?: string;
  selected?: boolean;
}

export interface SelectableListGroup {
  key: string;
  display?: string;
  icon?: IconsType;
  items: SelectableListItem[];
}

interface SelectableListProps {
  groups?: SelectableListGroup[];
  items?: SelectableListItem[];
  onClick?: (item: SelectableListItem, evt: MouseEvent) => void;
  onFocusEndOfList?: () => void;
  /** For aria roles when using this list in different contexts */
  role?: string;
}

/**
 * The SelectableList is a core part of many of our pickers: our Select dropdown, MultiSelect, and
 * SearchSelect components all use it, and it's one of the most common children of the Hovercard in
 * the HovercardToggle component. Many of the options will seem familiar if you've used any of
 * those: each item can take an optional `icon`, `secondaryDisplay`, as well as their own onClick
 * handler; it can also take groups instead of a single array of items.
 *
 * Ultimately, this is just a list of items that we also enable focusing with up/down arrows.
 */
export default function SelectableList({
  groups = [],
  items = [],
  onClick = noop,
  onFocusEndOfList,
  role = "menu",
}: SelectableListProps) {
  const listRef = useRef<HTMLUListElement>();

  const getListItem = ({ key, display, selected, ...rest }: SelectableListItem) => (
    <li key={key}>
      <Button
        className={classnames({ selected })}
        onClick={(evt) => onClick({ key, display, ...rest }, evt)}
        {...rest}
        role={role === "menu" ? "menuitem" : undefined}
      >
        {rest.secondaryDisplay ?
          <span>
            <TextOverflow>{display}</TextOverflow>
            <small>{rest.secondaryDisplay}</small>
          </span>
        : <TextOverflow>{display}</TextOverflow>}
        <SvgIcon name="check" />
      </Button>
    </li>
  );

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      // takes all buttons or links that are not disabled
      const itemsList = [...listRef.current.querySelectorAll("button, a")].filter(
        (el) => el && !el.hasAttribute("disabled"),
      ) as (HTMLButtonElement | HTMLAnchorElement)[];
      const focusedIndex = itemsList.findIndex((el) => el === document.activeElement);

      if (evt.key === "ArrowDown") {
        evt.preventDefault();
        itemsList.at((focusedIndex + 1) % itemsList.length)?.focus();

        if (focusedIndex === itemsList.length - 1 && onFocusEndOfList) {
          onFocusEndOfList();
        } else {
          itemsList.at((focusedIndex + 1) % itemsList.length)?.focus();
        }
      } else if (evt.key === "ArrowUp") {
        evt.preventDefault();

        if (!focusedIndex && onFocusEndOfList) {
          onFocusEndOfList();
        } else {
          itemsList.at(Math.max(-1, focusedIndex - 1) % itemsList.length)?.focus();
        }
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <ul ref={listRef} className="SelectableList" role={role}>
      {groups.length ?
        groups.map(({ key, icon, display, items }) => (
          <li key={key}>
            {display ?
              // TODO: we can remove this when we can use @scope
              <h5 className="group-title">
                {icon ?
                  <SvgIcon name={icon} />
                : null}
                {display}
              </h5>
            : null}
            <ul>{items.map(getListItem)}</ul>
          </li>
        ))
      : items.map(getListItem)}
    </ul>
  );
}
