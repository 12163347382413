import { getAddressShorthand } from "@/js/utils/property";

import { PropertyImage } from "../PropertyCard";
import TextOverflow from "../TextOverflow";

/**
 * A common way we display properties: name as the header and shortened address underneath, with an
 * optional image.
 */
export default function PropertyDisplay({
  property,
  withImage,
}: {
  property: {
    name: string;
    image?: string;
    address: {
      city?: string;
      state?: string;
      full_country_name?: string;
    };
  };
  withImage?: boolean;
}) {
  return (
    <div className="PropertyDisplay">
      {withImage ?
        <PropertyImage src={property.image} />
      : null}
      <div>
        <h5>
          <TextOverflow>{property.name}</TextOverflow>
        </h5>
        <p>
          <TextOverflow>{getAddressShorthand(property.address)}</TextOverflow>
        </p>
      </div>
    </div>
  );
}
