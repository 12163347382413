import { Model } from "resourcerer";

import { PropertyManager } from "@/Api/generated";

export default class PropertyManagerModel extends Model<PropertyManager> {
  url({ userToken }: { userToken: string }) {
    return `/api/property-manager/${userToken}`;
  }

  static idAttribute = "user_token";

  static dependencies = ["userToken"];
}
