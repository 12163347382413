/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Electric` - Electric
 * * `Natural Gas` - Natural Gas
 * * `Steam` - Steam
 * * `Hot Water` - Hot Water
 * * `Chilled Water` - Chilled Water
 * * `Fuel Oil` - Fuel Oil
 * * `Propane` - Propane
 */
export enum EnergySourceEnum {
    ELECTRIC = 'Electric',
    NATURAL_GAS = 'Natural Gas',
    STEAM = 'Steam',
    HOT_WATER = 'Hot Water',
    CHILLED_WATER = 'Chilled Water',
    FUEL_OIL = 'Fuel Oil',
    PROPANE = 'Propane',
}
