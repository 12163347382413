export const BEACON_PORTFOLIO_ORGANIZATION_TOKEN = "o_mKHcxiHqN2iRA9TtBbwJ7X";
export const BEACON_PILOT_ORGANIZATION_TOKEN = "o_m29XV2LpGQ2XKekQSL2ZKy";
export const CROWN_ORGANIZATION_TOKEN = "o_3ZYFCML8nJfPF8KZ36fLbY";
export const NUVEEN_ORGANIZATION_TOKEN = "o_uRAVasCKzguVz7jXRS2jjL";
export const NUVEEN_IMPACT_ORGANIZATION_TOKEN = "o_D7BYnHxTts2dsrfBrQVyA2";
export const INFRASTRUCTURE_ONTARIO_ORGANIZATION_TOKEN = "o_oNEpFghdgZtenFtFnUscTA";
export const OXFORD_PROPERTIES_PILOT_ORGANIZATION_TOKEN = "o_7brRkXUG7rZL5PLgdRbpjQ";
export const GSA_ORGANIZATION_TOKEN = "o_3DoitVERYGRMnjPMdaF6y5";
export const GOLDMAN_ORGANIZATION_TOKEN = "o_Foiie2Pys5CYjA46dyKZjM";

//Demo organizations
export const US_OFFICE_INDUSTRIAL_ORGANIZATION_TOKEN = "o_ZfQbVfUK8ThGEyzmwTJAg2";
export const LONG_BEACH_INDUSTRIAL_ORGANIZATION_TOKEN = "o_zWZmBEvocCA8Dkq4ejF5zS";
export const EUROPEAN_HOSPITALITY_ORGANIZATION_TOKEN = "o_37rVBE8tv9gnXgdymLGyYu";
export const GENERAL_INDUSTRIAL_ORGANIZATION_TOKEN = "o_3rzNj6ENFCJpwGE8Dpj8Aj";

export const DEFAULT_BUILDING_IMAGE_PATH = "/images/default-property-image.svg";

// these colors can be used one by one or in order by iterating over the values of this object
// TODO: the best thing to do here would be to link these with our CSS variables via
// getComputedStyle(document.documentElement).getPropertyValue(customPropertyName).
export const ChartColors = {
  TEAL_DARK: "#00847d",
  TEAL_CAMBIO: "#00beb1",
  LIME: "#aadb93",
  LEMON: "#eee857",
  ORANGE: "#f6be65",
  BLUE_SKY: "#8bcfed",
  BLUE: "#5797C6",
  INDIGO: "#7875fe",
  PURPLE: "#b58bfa",
  PINK: "#ec89cb",
  CORAL: "#f67777",
  BROWN: "#9F7D56",
  FORESTGREEN: "#427166",
  TEAL_DEEP: "#0a4949",
  BLUE_NAVY: "#256ca0",
};
