/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `Roof Top Unit` - Rtu
 * * `Air Handling Unit` - Ahu
 * * `Variable Refrigerant Flow` - Vrf
 * * `Boiler` - Boiler
 * * `Chiller` - Chiller
 * * `Heat Pump` - Heat Pump
 * * `Steam System` - Steam System
 * * `Cooling Tower` - Cooling Tower
 */
export enum HVACSystemCategoryEnum {
    ROOF_TOP_UNIT = 'Roof Top Unit',
    AIR_HANDLING_UNIT = 'Air Handling Unit',
    VARIABLE_REFRIGERANT_FLOW = 'Variable Refrigerant Flow',
    BOILER = 'Boiler',
    CHILLER = 'Chiller',
    HEAT_PUMP = 'Heat Pump',
    STEAM_SYSTEM = 'Steam System',
    COOLING_TOWER = 'Cooling Tower',
}
