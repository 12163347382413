import { register, ResourcesConfig } from "resourcerer";

import AggregateCertificationsCollection from "@/js/models/AggregateCertificationsCollection";
import AssetClassesCollection from "@/js/models/AssetClassesCollection";
import AssignedPropertiesCollection from "@/js/models/AssignedPropertiesCollection";
import BuildingSystemInfoModel from "@/js/models/BuildingSystemInfoModel";
import CampusModel from "@/js/models/CampusModel";
import CarbonMitigationForecastModel from "@/js/models/CarbonMitigationForecastModel";
import CertificationsCollection from "@/js/models/CertificationsCollection";
import ClimateRisksCollection from "@/js/models/ClimateRisksCollection";
import CrremStrandingAnalysisModel from "@/js/models/CrremStrandingAnalysisModel";
import DefaultMetricsDateRangeModel from "@/js/models/DefaultMetricsDateRangeModel";
import EmissionReductionModel, {
  EmissionReductionOldModel,
} from "@/js/models/EmissionReductionModel";
import EnergyConsumptionModel from "@/js/models/EnergyConsumptionModel";
import EnergySourceModel from "@/js/models/EnergySourceModel";
import FeatureFlagsModel, { CoreFeatureFlagsModel } from "@/js/models/featureFlagsModel";
import FinesModel from "@/js/models/FinesModel";
import MeterBreakdownModel from "@/js/models/MeterBreakdownModel";
import MeterDetailModel from "@/js/models/MeterDetailModel";
import MeterIntervalDataModel from "@/js/models/MeterIntervalDataModel";
import MetersCollection from "@/js/models/MetersCollection";
import OnboardingSummaryModel from "@/js/models/OnboardingSummaryModel";
import OrganizationsCollection from "@/js/models/organizationsCollection";
import OrgSummariesCollection from "@/js/models/OrgSummariesCollection";
import PartnersCollection from "@/js/models/PartnersCollection";
import PledgesCollection from "@/js/models/PledgesCollection";
import PropertiesCollection from "@/js/models/PropertiesCollection";
import PropertyFiltersCollection from "@/js/models/PropertyFiltersCollection";
import PropertyMetricsCollection from "@/js/models/PropertyMetricsCollection";
import PropertyModel from "@/js/models/PropertyModel";
import RegulatoryOrdinancesCollection from "@/js/models/RegulatoryOrdinancesCollection";
import RenewableEnergyModel from "@/js/models/RenewableEnergyModel";
import RetrofitProjectsCollection from "@/js/models/RetrofitProjectsCollection";
import RoiForecastModel from "@/js/models/RoiForecastModel";
import ScopeBreakdownCollection from "@/js/models/ScopeBreakdownCollection";
import SearchModel from "@/js/models/SearchModel";
import SpaceMeterAssignmentsCollection from "@/js/models/SpaceMeterAssignmentsCollection";
import SurveyConfigurationModel from "@/js/models/SurveyConfigurationModel";
import SurveyResultsModel from "@/js/models/SurveyResultsModel";
import SurveyCollection from "@/js/models/SurveysCollection";
import SurveyScoreModel from "@/js/models/SurveyScoreModel";
import TotalFootprintModel from "@/js/models/TotalFootprintModel";
import UnitHistoryCollection from "@/js/models/UnitHistoryCollection";
import UnitsCollection from "@/js/models/UnitsCollection";
import UserModel from "@/js/models/UserModel";
import UtilityProvidersCollection from "@/js/models/UtilityProvidersCollection";
import UtilitySpendingModel from "@/js/models/UtilitySpendingCollection";
import { removeAccessData } from "@/js/utils/authentication";

import PropertyManagerModel from "../models/PropertyManagerModel";
import WeatherDataModel from "../models/WeatherDataModel";

declare module "resourcerer" {
  export interface ModelMap {
    aggregateCertifications: new () => AggregateCertificationsCollection;
    assetClasses: new () => AssetClassesCollection;
    assignedProperties: new () => AssignedPropertiesCollection;
    buildingSystemInfo: new () => BuildingSystemInfoModel;
    campus: new () => CampusModel;
    carbonMitigationForecast: new () => CarbonMitigationForecastModel;
    certifications: new () => CertificationsCollection;
    climateRisks: new () => ClimateRisksCollection;
    crremStrandingAnalysis: new () => CrremStrandingAnalysisModel;
    defaultMetricsDateRange: new () => DefaultMetricsDateRangeModel;
    featureFlags: new () => FeatureFlagsModel;
    fines: new () => FinesModel;
    emissionReduction: new () => EmissionReductionModel;
    emissionReductionOld: new () => EmissionReductionOldModel;
    energyConsumption: new () => EnergyConsumptionModel;
    energySource: new () => EnergySourceModel;
    meters: new () => MetersCollection;
    meterBreakdown: new () => MeterBreakdownModel;
    meterDetail: new () => MeterDetailModel;
    meterIntervalData: new () => MeterIntervalDataModel;
    onboardingSummary: new () => OnboardingSummaryModel;
    orgSummaries: new () => OrgSummariesCollection;
    partners: new () => PartnersCollection;
    pledges: new () => PledgesCollection;
    properties: new () => PropertiesCollection;
    propertyManager: new () => PropertyManagerModel;
    propertyMetrics: new () => PropertyMetricsCollection;
    propertyFilters: new () => PropertyFiltersCollection;
    property: new () => PropertyModel;
    regulatoryOrdinances: new () => RegulatoryOrdinancesCollection;
    renewableEnergy: new () => RenewableEnergyModel;
    retrofitProjects: new () => RetrofitProjectsCollection;
    roiForecast: new () => RoiForecastModel;
    scopeBreakdown: new () => ScopeBreakdownCollection;
    search: new () => SearchModel;
    spaceMeterAssignments: new () => SpaceMeterAssignmentsCollection;
    surveys: new () => SurveyCollection;
    surveyResults: new () => SurveyResultsModel;
    surveyScore: new () => SurveyScoreModel;
    totalFootprint: new () => TotalFootprintModel;
    units: new () => UnitsCollection;
    unitHistory: new () => UnitHistoryCollection;
    utilitySpending: new () => UtilitySpendingModel;
    utilityProviders: new (
      attrs: NonNullable<unknown>,
      options: { utilityType: string },
    ) => UtilityProvidersCollection;
    weatherData: new () => WeatherDataModel;

    coreFeatureFlags: new () => CoreFeatureFlagsModel;
    organizations: new () => OrganizationsCollection;
    user: new () => UserModel;
    surveyConfiguration: new () => SurveyConfigurationModel;
  }
}

register({
  aggregateCertifications: AggregateCertificationsCollection,
  assetClasses: AssetClassesCollection,
  assignedProperties: AssignedPropertiesCollection,
  buildingSystemInfo: BuildingSystemInfoModel,
  campus: CampusModel,
  carbonMitigationForecast: CarbonMitigationForecastModel,
  certifications: CertificationsCollection,
  climateRisks: ClimateRisksCollection,
  crremStrandingAnalysis: CrremStrandingAnalysisModel,
  defaultMetricsDateRange: DefaultMetricsDateRangeModel,
  emissionReduction: EmissionReductionModel,
  emissionReductionOld: EmissionReductionOldModel,
  energyConsumption: EnergyConsumptionModel,
  energySource: EnergySourceModel,
  featureFlags: FeatureFlagsModel,
  fines: FinesModel,
  meters: MetersCollection,
  meterBreakdown: MeterBreakdownModel,
  meterDetail: MeterDetailModel,
  meterIntervalData: MeterIntervalDataModel,
  onboardingSummary: OnboardingSummaryModel,
  orgSummaries: OrgSummariesCollection,
  partners: PartnersCollection,
  pledges: PledgesCollection,
  properties: PropertiesCollection,
  propertyManager: PropertyManagerModel,
  propertyMetrics: PropertyMetricsCollection,
  propertyFilters: PropertyFiltersCollection,
  property: PropertyModel,
  regulatoryOrdinances: RegulatoryOrdinancesCollection,
  renewableEnergy: RenewableEnergyModel,
  retrofitProjects: RetrofitProjectsCollection,
  roiForecast: RoiForecastModel,
  scopeBreakdown: ScopeBreakdownCollection,
  search: SearchModel,
  spaceMeterAssignments: SpaceMeterAssignmentsCollection,
  surveyConfiguration: SurveyConfigurationModel,
  surveyResults: SurveyResultsModel,
  surveyScore: SurveyScoreModel,
  surveys: SurveyCollection,

  totalFootprint: TotalFootprintModel,
  units: UnitsCollection,
  unitHistory: UnitHistoryCollection,
  utilitySpending: UtilitySpendingModel,
  utilityProviders: UtilityProvidersCollection,
  weatherData: WeatherDataModel,

  // these three are our core resources. we'll always have these when we load the app
  // in a logged-in state
  organizations: OrganizationsCollection,
  coreFeatureFlags: CoreFeatureFlagsModel,
  user: UserModel,
});

ResourcesConfig.set({
  prefilter: (options = {}) => ({
    headers: options.headers,

    // global error handler, which is nice for handling 401s, 400s, 429s, etc
    error(response) {
      if (response.status === 401 && window.location.pathname !== "/signin") {
        removeAccessData();
        window.location.assign("/signin");

        return null;
      } else if (response.status === 400) {
        // TODO: log this to rollbar
      }

      return response;
    },
  }),
});
