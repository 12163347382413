/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `UTILITY_PORTAL_CSV` - Utility Portal Csv
 * * `UTILITY_PORTAL_MANUAL_INPUT` - Utility Portal Manual Input
 * * `URJANET_API` - Urjanet Api
 * * `ENERGY_STAR` - Energy Star
 * * `INTERNAL_SPREADSHEET` - Internal Spreadsheet
 * * `RETOOL_APP` - Retool App
 * * `ADMIN_BILL_UPLOAD` - Admin Bill Upload
 * * `GRESB_ASSET_SPREADSHEET` - Gresb Asset Spreadsheet
 * * `ON_PLATFORM_UTILITY_BILL_UPLOAD` - On Platform Utility Bill Upload
 * * `MANUAL_ENTRY` - Manual Entry
 */
export enum IngestionRecordSourceEnum {
    UTILITY_PORTAL_CSV = 'UTILITY_PORTAL_CSV',
    UTILITY_PORTAL_MANUAL_INPUT = 'UTILITY_PORTAL_MANUAL_INPUT',
    URJANET_API = 'URJANET_API',
    ENERGY_STAR = 'ENERGY_STAR',
    INTERNAL_SPREADSHEET = 'INTERNAL_SPREADSHEET',
    RETOOL_APP = 'RETOOL_APP',
    ADMIN_BILL_UPLOAD = 'ADMIN_BILL_UPLOAD',
    GRESB_ASSET_SPREADSHEET = 'GRESB_ASSET_SPREADSHEET',
    ON_PLATFORM_UTILITY_BILL_UPLOAD = 'ON_PLATFORM_UTILITY_BILL_UPLOAD',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
}
