import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import ModalSequenceManager from "../ModalSequenceManager";

export type ModalState = { sequence: Array<JSX.Element>; childModal?: JSX.Element };

type ModalAction =
  | { type: "start"; payload: JSX.Element }
  | { type: "next"; payload?: JSX.Element }
  | { type: "prev" }
  | { type: "reset" }
  | { type: "showChildModal"; payload?: JSX.Element }
  | { type: "hideChildModal" };

type ModalContext = {
  /** This will be the most-used method, which starts a modal sequence */
  launch: (modal: JSX.Element, cb?: () => void) => void;
  /** Call this to continue a modal sequence, or end the sequence with no argument */
  next: (modal?: JSX.Element, cb?: () => void) => void;
  /** Call this to move back in a modal sequence */
  prev: () => void;
  /** Ends the sequence */
  end: () => void;
  msmRef: MutableRefObject<HTMLDivElement>;
  hasPrev: boolean;

  /** This setter/getter duo is for keeping track of data throughout the course of a sequence */
  setSequenceData: (obj: Record<string, any>) => void;
  getSequenceData: (key: string) => any;
};

const ModalContext = createContext<ModalContext>({} as ModalContext);

export const modalReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case "start":
      return { sequence: [action.payload] };
    case "next":
      return { sequence: action.payload ? state.sequence.concat(action.payload) : [] };
    case "prev":
      return { sequence: state.sequence.slice(0, -1) };
    case "showChildModal":
      return { ...state, childModal: action.payload };
    case "hideChildModal":
      return { sequence: state.sequence };
    case "reset":
      return { sequence: [] };
  }
};

export const withModalContextProvider =
  (Component: (props: any) => JSX.Element) => (props: PropsWithChildren) => {
    const [state, dispatch] = useReducer(modalReducer, { sequence: [] });
    const sequenceData = useRef<Record<string, any>>({});
    const callbackRef = useRef<() => void>();
    const msmRef = useRef<HTMLDivElement>();

    const value: ModalContext = useMemo(
      () => ({
        launch: (modal, cb) => {
          callbackRef.current = cb;

          !state.sequence.length ?
            dispatch({ type: "start", payload: modal })
            // if we're already in a sequence, then launch this as a child
          : dispatch({ type: "showChildModal", payload: modal });
        },
        prev: () => dispatch({ type: "prev" }),
        next: (modal, cb) => {
          if (cb) {
            callbackRef.current = cb;
          }

          dispatch({ type: "next", payload: modal });
        },
        end: () => {
          if ("childModal" in state) {
            // if we have a child modal, close that first
            dispatch({ type: "hideChildModal" });
          } else {
            dispatch({ type: "reset" });
          }
        },

        hasPrev: state.sequence.length > 1,
        msmRef,

        setSequenceData: (obj) => Object.assign(sequenceData.current, obj),
        getSequenceData: (key) => sequenceData.current[key],
      }),
      [state],
    );

    useEffect(() => {
      if (!state.sequence.length) {
        // fire the callback and reset refs
        callbackRef.current?.();
        sequenceData.current = {};
      }
    }, [state.sequence.length]);

    return (
      <ModalContext.Provider value={value}>
        <ModalSequenceManager {...state} />
        <Component {...props} />
      </ModalContext.Provider>
    );
  };

export default ModalContext;
