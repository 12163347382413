import type { LocalStorageKeys } from "@/js/core/localStorage";

import { useEffect, useState } from "react";

import { getEffectiveUser, getPortfolioOrg } from "@/js/core/coreResources";
import LocalStorage, { generateKey } from "@/js/core/localStorage";

interface LocalStorageOptions {
  /** when true, the keys are not distinct by user id and org id */
  global?: boolean;
}

/**
 * Hook to easily interface and react to localStorage changes. Adds a listener so that changes
 * cause re-renders not only in different components but also in different browser tabs.
 *
 * Returns a convenient tuple of [currentValue, setValue, removeValue].
 */
export default function useLocalStorage<T>(
  key: LocalStorageKeys,
  defaultValue?: T,
  options: LocalStorageOptions = {} as LocalStorageOptions,
) {
  const [, forceUpdate] = useState({});
  const userId = !options.global ? getEffectiveUser().get("token") : "";
  const orgId = !options.global ? getPortfolioOrg().organization__token : "";

  /**
   * Adds a storage event listener, which by default will respond to storage events in another tab
   * and cause the client component to re-render. However, we also create and dispatch our own
   * storage event anytime we set a new value (without the "silent" flag) to localStorage, so any
   * client component on the same tab will also re-render. This allows us to be reactive to
   * localStorage updates!
   */
  useEffect(() => {
    // respond to storage events in another tab.
    const onStorageEvent = (evt: StorageEvent) => {
      if (evt.storageArea === window.localStorage && evt.key === generateKey(key, userId, orgId)) {
        forceUpdate({});
      }
    };

    window.addEventListener("storage", onStorageEvent);

    return () => window.removeEventListener("storage", onStorageEvent);
  }, []);

  return [
    // getter
    LocalStorage.get(key, userId, orgId) || defaultValue,
    // setter
    (value: T, options = { silent: false }) => {
      LocalStorage.set(key, value, userId, orgId);

      // pass silent: true if this setting local storage is an effect and not part of data flow
      if (!options.silent) {
        dispatchStorageEvent(generateKey(key, userId, orgId));
        forceUpdate({});
      }
    },
    // remover
    (options = { silent: false }) => {
      LocalStorage.remove(key, userId, orgId);

      // pass silent: true if this setting local storage is an effect and not part of data flow
      if (!options.silent) {
        dispatchStorageEvent(generateKey(key, userId, orgId));
        forceUpdate({});
      }
    },
  ];
}

/**
 * This will trigger our storage listener in the effect of this hook, which will update
 * all other listening components! This otherwise wouldn't happen by default--the storage
 * event only triggers on other tabs.
 */
function dispatchStorageEvent(key: string) {
  window.dispatchEvent(new StorageEvent("storage", { key, storageArea: window.localStorage }));
}
