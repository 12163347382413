/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `CAMBIO_RECOMMENDED` - Cambio Recommended
 * * `CUSTOMER_CREATED` - Customer Created
 */
export enum ProjectOriginEnum {
    CAMBIO_RECOMMENDED = 'CAMBIO_RECOMMENDED',
    CUSTOMER_CREATED = 'CUSTOMER_CREATED',
}
